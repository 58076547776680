import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, Field, ErrorMessage, FastField } from "formik";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import DatePickerField from "../element/date-picker/DatePickerField";
import "react-datepicker/dist/react-datepicker.css";
import { commonActions } from "../../store/slices/common";
import { handleGetJersey } from "../../store/thunk/jersey.js";
import { useTranslation } from "react-i18next";
import { WidthFull } from "@mui/icons-material";

const bloodTypeOptions = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "AB", label: "AB" },
  { value: "O", label: "O" },
];

const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

const officialCommunityOptions = [
  {
    value: "Perangkat Daerah Provinsi Jawa Barat",
    label: "Perangkat Daerah Provinsi Jawa Barat",
  },
  { value: "Bapenda Kabupaten/Kota", label: "Bapenda Kabupaten/Kota" },
  {
    value: "Unsur Lembaga Lainnya / Komunitas / Individu",
    label: "Unsur Lembaga Lainnya / Komunitas / Individu",
  },
];

const FormParticipants = ({
  setFlagPurchase,
  bundlingCount,
  name,
  setForm,
  ticketQuantity,
  ticketId,
  ticketType,
  values,
  indexTicket,
  eventId,
}) => {
  const dispatch = useDispatch();
  const { unisex, kids } = useSelector((state) => state.jersey);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(handleGetJersey("unisex", "/unisex/" + eventId));
    dispatch(handleGetJersey("kids", "/kids/" + eventId));
  }, [dispatch, eventId]);

  let jerseyUnisex = [];
  let jerseyKids = [];
  const gdr = values[name]?.[indexTicket]?.gender;
  const genderLabel = gdr === "male" ? "Reguler/Male" : "Female";
  for (let i = 0; i < unisex.data.length; i++) {
    let value = `${unisex.data[i].size_tag}`;
    let label = `${unisex.data[i].size_tag}`;
    if (unisex.data[i].event_id === 4) {
      value = `${unisex.data[i].size_tag}`;
      label = `${unisex.data[i].size_tag}`;
    }

    jerseyUnisex.push({
      value: value,
      label: label,
      quota: unisex?.data[i]?.quota,
    });
  }
  for (let i = 0; i < kids.data.length; i++) {
    jerseyKids.push({
      value: `${kids.data[i].size_id}+${i}_${kids.data[i].size_tag} (width ${kids.data[i].size_width}cm / length ${kids.data[i].size_length})`,
      label: `${kids.data[i].size_tag} (width ${kids.data[i].size_width}cm / length ${kids.data[i].size_length})`,
    });
  }
  const objParticipant = {
    ticket_id: ticketId,
    fullName: "",
    voucher_name: "",
    email: "",
    birth: "",
    gender: "",
    nik: "",
    phone: "",
    nationality: "",
    address: "",
    bloodType: "",
    emergencyContactName: "",
    emergencyContactPhone: "",
    jerseySize: "",
    jerseySizeKids: "",
    diseaseHistory: "",
    officialCommunity: "",
    from_official_community: "",
  };
  const maxTicket = 10;
  const handleAddParticipant = (push, form) => {
    const participants = form.values[name] || [];

    for (let i = 0; i < bundlingCount - 1; i++) {
      if (participants.length < bundlingCount) {
        push(objParticipant);
      } else {
        dispatch(
          commonActions.showToast({
            message: `Maximum of ${bundlingCount} participants can be added for Commu`,
            type: "warn",
          }),
        );
        break;
      }
    }

    if (participants.length < maxTicket) {
      push(objParticipant);
    } else {
      dispatch(
        commonActions.showToast({
          message: "Maximum of " + bundlingCount + " participants can be added",
          type: "warn",
        }),
      );
    }
  };

  // const handleRemoveAllParticipants = (remove, ticket_tag) => {
  //   if (ticket_tag === "formComTenK" || ticket_tag === "formComFiveK") {
  //     while (remove(0)) {}
  //   }
  // };

  const radius = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "20px",
    },
  };

  return (
    <FieldArray name={name}>
      {({ form, push, remove }) => {
        const participants = form.values[name] || []; // Default to empty array
        return (
          <>
            {participants.map((_, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                    Participant {index + 1}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  {/* <FormikTextFieldIndex index={index} name={`${name}[${index}].fullName`} label="Full Name" /> */}
                  <FastField
                    as={TextField}
                    name={`${name}[${index}].fullName`}
                    label="Full Name"
                    fullWidth
                    variant="outlined"
                    error={
                      !!form.errors[name]?.[index]?.fullName &&
                      form.touched[name]?.[index]?.fullName
                    }
                    helperText={
                      <ErrorMessage name={`${name}[${index}].fullName`} />
                    }
                    sx={radius}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  {/* <FormikTextFieldIndex index={index} name={`${name}[${index}].email`} label="Email" /> */}
                  <Field
                    as={TextField}
                    name={`${name}[${index}].email`}
                    label="Email"
                    fullWidth
                    variant="outlined"
                    error={
                      !!form.errors[name]?.[index]?.email &&
                      form.touched[name]?.[index]?.email
                    }
                    helperText={
                      <ErrorMessage name={`${name}[${index}].email`} />
                    }
                    sx={radius}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DatePickerField
                    name={`${name}[${index}].birth`}
                    label="Birth Date"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    as={TextField}
                    name={`${name}[${index}].nik`}
                    label="KTP/Passport"
                    fullWidth
                    variant="outlined"
                    error={
                      !!form.errors[name]?.[index]?.nik &&
                      form.touched[name]?.[index]?.nik
                    }
                    helperText={<ErrorMessage name={`${name}[${index}].nik`} />}
                    sx={radius}
                  />
                  <Typography variant="caption" color="textSecondary">
                    For children, use the parent/guardian's ID card
                  </Typography>
                </Grid>
                <Grid item md={name === "FSPNRFES" ? 12 : 6} xs={12}>
                  <Field
                    as={TextField}
                    name={`${name}[${index}].phone`}
                    label="Phone"
                    fullWidth
                    variant="outlined"
                    error={
                      !!form.errors[name]?.[index]?.phone &&
                      form.touched[name]?.[index]?.phone
                    }
                    helperText={
                      <ErrorMessage name={`${name}[${index}].phone`} />
                    }
                    sx={radius}
                  />
                  <Typography variant="caption" color="textSecondary">
                    WhatsApp Number Active (ex: 08xxxxxxxx)
                  </Typography>
                </Grid>
                {name !== "FSPNRFES" && (
                  <Grid item md={6} xs={12}>
                    <Field
                      as={TextField}
                      name={`${name}[${index}].nationality`}
                      label="Nationality"
                      fullWidth
                      variant="outlined"
                      error={
                        !!form.errors[name]?.[index]?.nationality &&
                        form.touched[name]?.[index]?.nationality
                      }
                      helperText={
                        <ErrorMessage name={`${name}[${index}].nationality`} />
                      }
                      sx={radius}
                    />
                  </Grid>
                )}
                <Grid item md={12} xs={12}>
                  <Field
                    as={TextField}
                    name={`${name}[${index}].address`}
                    label="Full Address"
                    fullWidth
                    variant="outlined"
                    error={
                      !!form.errors[name]?.[index]?.address &&
                      form.touched[name]?.[index]?.address
                    }
                    helperText={
                      <ErrorMessage name={`${name}[${index}].address`} />
                    }
                    sx={radius}
                  />
                </Grid>
                {name !== "FSPNRFES" && (
                  <Grid item md={6} xs={12}>
                    <Field
                      as={TextField}
                      select
                      name={`${name}[${index}].bloodType`}
                      label="Blood Type"
                      fullWidth
                      variant="outlined"
                      error={
                        !!form.errors[name]?.[index]?.bloodType &&
                        form.touched[name]?.[index]?.bloodType
                      }
                      helperText={
                        <ErrorMessage name={`${name}[${index}].bloodType`} />
                      }
                      sx={radius}
                    >
                      <MenuItem value="">
                        <em>Select Blood Type</em>
                      </MenuItem>
                      {bloodTypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                )}
                <Grid item md={6} xs={12}>
                  <Field
                    as={TextField}
                    select
                    name={`${name}[${index}].gender`}
                    label="Gender"
                    fullWidth
                    variant="outlined"
                    error={
                      !!form.errors[name]?.[index]?.gender &&
                      form.touched[name]?.[index]?.gender
                    }
                    helperText={
                      <ErrorMessage name={`${name}[${index}].gender`} />
                    }
                    sx={radius}
                  >
                    <MenuItem value="">
                      <em>Select Gender</em>
                    </MenuItem>
                    {genderOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                {name !== "FSPNRFES" && (
                  <Grid item md={6} xs={12}>
                    <Field
                      as={TextField}
                      select
                      name={`${name}[${index}].jerseySize`}
                      label="Jersey Size"
                      fullWidth
                      variant="outlined"
                      error={
                        !!form.errors[name]?.[index]?.jerseySize &&
                        form.touched[name]?.[index]?.jerseySize
                      }
                      helperText={
                        !!form.errors[name]?.[index]?.jerseySize &&
                        form.touched[name]?.[index]?.jerseySize
                          ? form.errors[name][index].jerseySize
                          : form.errors[name]?.[index]?.jerseySizeKids &&
                              form.touched[name]?.[index]?.jerseySizeKids
                            ? form.errors[name][index].jerseySizeKids
                            : ""
                      }
                      sx={radius}
                    >
                      <MenuItem value="">
                        <em>Select Jersey Size</em>
                      </MenuItem>
                      {jerseyUnisex.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          disabled={option.quota <= 0}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                    <Typography variant="caption" color="textSecondary">
                      Jersey for Adult Participants
                    </Typography>
                  </Grid>
                )}
                {/* {ticketType === "family" && ( */}
                {/*   <Grid item md={6} xs={12}> */}
                {/*     <Field */}
                {/*       as={TextField} */}
                {/*       select */}
                {/*       name={`${name}[${index}].jerseySizeKids`} */}
                {/*       label="Jersey Size Kids" */}
                {/*       fullWidth */}
                {/*       variant="outlined" */}
                {/*       error={ */}
                {/*         !!form.errors[name]?.[index]?.jerseySizeKids && */}
                {/*         form.touched[name]?.[index]?.jerseySizeKids */}
                {/*       } */}
                {/*       helperText={ */}
                {/*         !!form.errors[name]?.[index]?.jerseySizeKids && */}
                {/*         form.touched[name]?.[index]?.jerseySizeKids */}
                {/*           ? form.errors[name][index].jerseySizeKids */}
                {/*           : form.errors[name]?.[index]?.jerseySize && */}
                {/*               form.touched[name]?.[index]?.jerseySize */}
                {/*             ? form.errors[name][index].jerseySize */}
                {/*             : "" */}
                {/*       } */}
                {/*       sx={radius} */}
                {/*     > */}
                {/*       <MenuItem value=""> */}
                {/*         <em>Select Jersey Size</em> */}
                {/*       </MenuItem> */}
                {/*       {jerseyKids.map((option) => ( */}
                {/*         <MenuItem key={option.value} value={option.value}> */}
                {/*           {option.label} */}
                {/*         </MenuItem> */}
                {/*       ))} */}
                {/*     </Field> */}
                {/*     <Typography variant="caption" color="textSecondary"> */}
                {/*       Jersey for Child Participants */}
                {/*     </Typography> */}
                {/*   </Grid> */}
                {/* )} */}
                {name !== "FSPNRFES" && (
                  <Grid item md={6} xs={12}>
                    <Field
                      as={TextField}
                      name={`${name}[${index}].diseaseHistory`}
                      label={t("diseaseHistory")}
                      fullWidth
                      multiline
                      variant="outlined"
                      error={
                        !!form.errors[name]?.[index]?.diseaseHistory &&
                        form.touched[name]?.[index]?.diseaseHistory
                      }
                      helperText={
                        <ErrorMessage
                          name={`${name}[${index}].diseaseHistory`}
                        />
                      }
                      sx={{
                        borderRadius: radius,
                      }}
                    />
                  </Grid>
                )}
                <Grid item md={6} xs={12}>
                  <Field
                    as={TextField}
                    name={`${name}[${index}].emergencyContactName`}
                    label="Emergency Contact Name"
                    fullWidth
                    variant="outlined"
                    error={
                      !!form.errors[name]?.[index]?.emergencyContactName &&
                      form.touched[name]?.[index]?.emergencyContactName
                    }
                    helperText={
                      <ErrorMessage
                        name={`${name}[${index}].emergencyContactName`}
                      />
                    }
                    sx={radius}
                  />
                </Grid>
                <Grid item md={name === "FSPNRFES" ? 12 : 6} xs={12}>
                  <Field
                    as={TextField}
                    name={`${name}[${index}].emergencyContactPhone`}
                    label="Emergency Contact Phone"
                    fullWidth
                    variant="outlined"
                    error={
                      !!form.errors[name]?.[index]?.emergencyContactPhone &&
                      form.touched[name]?.[index]?.emergencyContactPhone
                    }
                    helperText={
                      <ErrorMessage
                        name={`${name}[${index}].emergencyContactPhone`}
                      />
                    }
                    sx={radius}
                  />
                  <Typography variant="caption" color="textSecondary">
                    WhatsApp Number Active (ex: 08xxxxxxxx)
                  </Typography>
                </Grid>
                {name === "FSPNR1" && (
                  <Grid item md={12} xs={12}>
                    <Field
                      as={TextField}
                      name={`${name}[${index}].voucher_name`}
                      label="Voucher"
                      fullWidth
                      variant="outlined"
                      error={
                        !!form.errors[name]?.[index]?.voucher_name &&
                        form.touched[name]?.[index]?.voucher_name
                      }
                      helperText={
                        <ErrorMessage name={`${name}[${index}].voucher_name`} />
                      }
                      sx={radius}
                    />
                  </Grid>
                )}
                <Grid item md={6} xs={12} style={{ display: "none" }}>
                  <Field
                    as={TextField}
                    select
                    name={`${name}[${index}].officialCommunity`}
                    label="Nama Komunitas / Dinas"
                    fullWidth
                    variant="outlined"
                    error={
                      !!form.errors[name]?.[index]?.officialCommunity &&
                      form.touched[name]?.[index]?.officialCommunity
                    }
                    helperText={
                      <ErrorMessage
                        name={`${name}[${index}].officialCommunity`}
                      />
                    }
                    sx={radius}
                  >
                    {officialCommunityOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item md={6} xs={12} style={{ display: "none" }}>
                  <Field
                    as={TextField}
                    name={`${name}[${index}].from_official_community`}
                    label="Asal Kedinasan/Komunitas"
                    fullWidth
                    variant="outlined"
                    error={
                      !!form.errors[name]?.[index]?.from_official_community &&
                      form.touched[name]?.[index]?.from_official_community
                    }
                    helperText={
                      <ErrorMessage
                        name={`${name}[${index}].from_official_community`}
                      />
                    }
                    sx={radius}
                  />
                </Grid>
                {name === "FSPNR3" || name === "FSPNR5" ? null : (
                  <Grid item md={12} xs={12}>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ borderRadius: "20px" }}
                      onClick={() => {
                        remove(index);
                        setFlagPurchase("");
                      }}
                    >
                      - Remove Participant
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            ))}
            <Grid item md={12} xs={12}>
              <Button
                type="button"
                onClick={() => {
                  handleAddParticipant(push, form);
                }}
                disabled={
                  !(ticketQuantity > participants.length) ||
                  (name === "FSPNREB" && participants.length === 1) ||
                  participants.length >= bundlingCount
                }
              >
                {participants.length === 0
                  ? "+ Get Ticket"
                  : "+ Get More Ticket"}
              </Button>
            </Grid>
            {/* {(name === "formComTenK" && */}
            {/*   form.values["formComTenK"].length > 1) || */}
            {/* (name === "formComFiveK" && */}
            {/*   form.values["formComFiveK"].length > 1) ? ( */}
            {/*   <Button */}
            {/*     type="button" */}
            {/*     variant="contained" */}
            {/*     color="error" */}
            {/*     // onClick={() => handleRemoveAllParticipants(remove, name)} */}
            {/*   > */}
            {/*     - Remove All Participants */}
            {/*   </Button> */}
            {/* ) : null} */}
          </>
        );
      }}
    </FieldArray>
  );
};

export default FormParticipants;
