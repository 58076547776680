import React, { useState, useEffect, useMemo } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
//import { Grid, TextField, Button, Typography, Divider } from "@mui/material";
import { Field, ErrorMessage } from "formik";
import styled from "styled-components";
import {
  Button,
  MenuItem,
  Grid,
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  Breadcrumbs,
  Link,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as Yup from "yup";
import FormikTextField from "../element/textfield/FormikTextField";
import ThemeVariation from "../../theme/theme-variation";
import Layout from "../layout";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { commonActions } from "../../store/slices/common";
import { handleGetTicket } from "../../store/thunk/events.js";
import { handleGetEventDetail } from "../../store/thunk/events.js";
import ConfirmationDialog from "./ConfirmationDialog";
import TicketDynamic from "./TicketDynamic.jsx";
import TicketSkeleton from "./TicketSkeleton";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Slider from "@mui/material/Slider";
import FormikSelectField from "../element/selectField/FormikSelectField.jsx";
import axios from "axios";
import { getValidateVoucher } from "../../services/voucher.js";
const { v4: uuidv4 } = require("uuid");

const CustomSlider = styled(Slider)({
  "& .MuiSlider-markLabel": {
    fontSize: "12px",
  },
  '& .MuiSlider-markLabel[data-index="1"]': {
    left: "90% !important",
    //transform: "translateX(-50%)", // Geser posisi label
    fontWeight: "bold",
  },
});

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// const validateParticipant = {
//   fullName: Yup.string().required("Full Name is required"),
//   email: Yup.string()
//     .email("Invalid email format")
//     .required("Email is required"),
//   birth: Yup.date().required("Date of birth is required"),
//   gender: Yup.string().required("Gender is required"),
//   nik: Yup.string().required("KTP/Passport is required"),
//   phone: Yup.string().required("Phone is required"),
//   nationality: Yup.string().required("Nationality is required"),
//   address: Yup.string().required("Address is required"),
//   bloodType: Yup.string().required("Blood Type is required"),
//   emergencyContactName: Yup.string().required(
//     "Emergency Contact Name is required",
//   ),
//   emergencyContactPhone: Yup.string().required(
//     "Emergency Contact Phone is required",
//   ),
//   officialCommunity: Yup.string(),
//   //voucher_name: Yup.string(),
//   voucher_name: Yup.string(),
//   // jerseySize: Yup.string().required('Jersey Size is required'),
//   // jerseySize: Yup.string(),
//   // jerseySizeKids: Yup.string()
// };
const validateParticipant = {
  fullName: Yup.string().required("Full Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  birth: Yup.date().required("Date of birth is required"),
  gender: Yup.string().required("Gender is required"),
  nik: Yup.string().required("KTP/Passport is required"),
  phone: Yup.string().required("Phone is required"),
  address: Yup.string().required("Address is required"),
  emergencyContactName: Yup.string().required(
    "Emergency Contact Name is required",
  ),
  emergencyContactPhone: Yup.string().required(
    "Emergency Contact Phone is required",
  ),

  // Conditionally validated fields
  nationality: Yup.string().when("$ticketTag", {
    is: "FSPNRFES",
    then: () => Yup.string().notRequired(),
    otherwise: () => Yup.string().required("Nationality is required"),
  }),
  bloodType: Yup.string().when("$ticketTag", {
    is: "FSPNRFES",
    then: () => Yup.string().notRequired(),
    otherwise: () => Yup.string().required("Blood Type is required"),
  }),
  jerseySize: Yup.string().when("$ticketTag", {
    is: "FSPNRFES",
    then: () => Yup.string().notRequired(),
    otherwise: () =>
      Yup.string().test(
        "jerseySize-or-jerseySizeKids",
        "Jersey Size is required",
        function (value) {
          return value || this.parent.jerseySizeKids;
        },
      ),
  }),
  diseaseHistory: Yup.string().when("$ticketTag", {
    is: "FSPNRFES",
    then: () => Yup.string().notRequired(),
    otherwise: () => Yup.string(),
  }),
};

const generateValidationSchema = (tickets) => {
  const shape = {
    fullName: Yup.string().required("Full Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
  };

  tickets.forEach((ticket) => {
    shape[ticket.ticket_tag] = Yup.array().of(
      Yup.object().shape({
        fullName: Yup.string().required("Full Name is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        birth: Yup.date().required("Date of birth is required"),
        gender: Yup.string().required("Gender is required"),
        nik: Yup.string().required("KTP/Passport is required"),
        phone: Yup.string().required("Phone is required"),
        address: Yup.string().required("Address is required"),
        emergencyContactName: Yup.string().required(
          "Emergency Contact Name is required",
        ),
        emergencyContactPhone: Yup.string().required(
          "Emergency Contact Phone is required",
        ),

        // Conditionally validated fields
        nationality:
          ticket.ticket_tag === "FSPNRFES"
            ? Yup.string().notRequired()
            : Yup.string().required("Nationality is required"),

        bloodType:
          ticket.ticket_tag === "FSPNRFES"
            ? Yup.string().notRequired()
            : Yup.string().required("Blood Type is required"),

        jerseySize:
          ticket.ticket_tag === "FSPNRFES"
            ? Yup.string().notRequired()
            : Yup.string().required("Jersey Size is required"),

        diseaseHistory:
          ticket.ticket_tag === "FSPNRFES"
            ? Yup.string().notRequired()
            : Yup.string(),

        // jerseySizeKids: Yup.string().test(
        //   "jerseySize-or-jerseySizeKids",
        //   "Either Jersey Size or Jersey Size Kids is required",
        //   function (value) {
        //     return value || this.parent.jerseySize;
        //   },
        // ),
      }),
    );
  });

  return Yup.object().shape(shape);
};
const BillingOrderForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { id } = useParams();
  const [totalCostDiscount, setTotalCostDiscount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState(null);
  const [participants, setParticipants] = useState({});
  const [sumTicketCost, setSumTicketCost] = useState(0);
  const [flagPurchase, setFlagPurchase] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [totalCostParticipants, setTotalCostParticipants] = useState(0);
  const [donationValue, setDonationValue] = useState(0);
  const [isloadingCheckVoucher, setIsLoadingCheckVoucher] = useState("");
  //const [selectedBundling, setSelectedBundling] = useState("FSPNR1");
  // const { data, fetching, error } = useSelector((state) => state.event.tickets);
  const {
    data: ticketData,
    fetching: ticketFetching,
    error: ticketError,
  } = useSelector((state) => state.event.tickets);
  const {
    data: eventData,
    fetching: eventFetching,
    error: eventError,
  } = useSelector((state) => state.event.detail);
  const eventFormulaData = eventData?.result?.event_formula?.data;
  const eventFormula = eventData?.result?.event_formula?.formula;

  const dataTickets = useMemo(() => {
    return ticketData.result || [];
  }, [ticketData.result]);

  useEffect(() => {
    if (id) {
      dispatch(handleGetTicket(id));
      dispatch(handleGetEventDetail(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    const initialTickets = dataTickets.reduce((acc, ticket) => {
      acc[ticket.ticket_tag] = [];
      return acc;
    }, {});
    setParticipants(initialTickets);
  }, [dataTickets]);
  const validationSchema = generateValidationSchema(dataTickets);

  const initialValues = {
    fullName: "",
    email: "",
    phone: "",
    dataTickets,
  };

  const realTotalCost = useMemo(() => {
    return totalCost + donationValue;
  }, [donationValue, totalCost]);

  const handleSubmit = (values, { setSubmitting }) => {
    setFormData(values);
    setOpenDialog(true);
    setSubmitting(false);
  };

  const handleConfirm = async () => {
    try {
      // Initialize cost variables
      let totalCost = 0;
      const costs = {};
      // Convert birth field to Date
      const convertDateToString = (date) => {
        return dayjs(date).format("YYYY-MM-DD"); // Change the format string as needed
      };

      // Process formData dynamically based on dataTickets
      const processedParticipants = {};

      dataTickets.forEach((ticket) => {
        const ticketName = ticket.ticket_tag;
        const ticketPrice = ticket.ticket_price;
        const participants =
          formData[ticketName]?.map((person) => ({
            ...person,
            birth: convertDateToString(person.birth),
          })) || [];

        processedParticipants[ticketName] = participants;
        const ticketCost = participants.length * ticketPrice;
        costs[ticketName] = ticketCost;
        totalCost += ticketCost;
      });
      // Check if all participant arrays are empty
      const allEmpty = Object.values(processedParticipants).every(
        (participants) => participants.length === 0,
      );

      if (allEmpty) {
        dispatch(
          commonActions.showToast({
            message: "Please complete the participant data",
            type: "warn",
          }),
        );
        return; // Stop the function if the validation fails
      }
      const data = {
        booking: {
          idBooking: uuidv4(),
          fullName: formData.fullName,
          phone: formData.phone,
          email: formData.email,
        },
        payment: {
          ...costs,
          totalCost: totalCostParticipants,
          totalCostDiscount,
          sumTicketCost: realTotalCost,
          donation: donationValue,
          isdonate: donationValue ? "true" : "false",
          redeemCode: formData.redeemCode,
        },
        participants: processedParticipants,
        dataTickets,
      };

      navigate("/invoice", { state: { ...data } });
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  const THEME = ThemeVariation.Secondary;
  //const bundlingOptionsChecking = useMemo(() => {}, []);
  //
  //

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="#"
      onClick={() => navigate(-2)}
    >
      HOME
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="#"
      onClick={() => navigate(-1)}
    >
      Event
    </Link>,
    <Typography key="3" color="text.primary">
      Checkout
    </Typography>,
  ];
  return (
    <Layout
      theme={THEME}
      showHeaderSpacing={false}
      color={
        (eventData?.result.event_name !== undefined ??
        eventData?.result.event_name.toUpperCase() === "JAWARA RUN 2024")
          ? eventData?.result.color
          : ThemeVariation.Base
      }
    >
      <Container>
        <Stack mt={2}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        <Grid>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
          >
            {({ values, errors, touched, isSubmitting }) => {
              return (
                <Box component={Form} sx={{ p: 3 }}>
                  <Grid container spacing={2}>
                    {/* Column 1: Billing Information and Form 10K */}
                    <Grid item xs={12} md={7}>
                      <Card sx={{ width: "100%", mb: 2 }}>
                        <CardContent sx={{ flex: "1 0 auto" }}>
                          <Typography variant="h4" gutterBottom>
                            {t("billingInformation")}
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <FormikTextField
                                name="fullName"
                                label="Full Name"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormikTextField name="email" label="Email" />
                            </Grid>
                            <Grid item xs={12}>
                              <FormikTextField name="phone" label="Phone" />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                      {ticketFetching && <TicketSkeleton />}
                      {dataTickets.map((ticket, i) => {
                        return (
                          <TicketDynamic
                            flagPurchase={flagPurchase}
                            setFlagPurchase={setFlagPurchase}
                            setDiscount={setTotalCostDiscount}
                            setLoadingVoucher={setIsLoadingCheckVoucher}
                            key={i}
                            ticket={ticket}
                            dataTickets={dataTickets}
                            form={values}
                            name={ticket["ticket_tag"]}
                            index={i}
                          />
                        );
                      })}
                    </Grid>

                    {/* Column 2: Payment Information */}
                    <Grid item xs={12} md={5}>
                      <Card sx={{ mb: 2 }}>
                        <CardContent sx={{ flex: "1 0 auto" }}>
                          <Typography variant="h4" gutterBottom>
                            Payment Information
                          </Typography>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ width: "30%" }}>
                                    Ticket Name
                                  </TableCell>
                                  <TableCell style={{ width: "10%" }}>
                                    Participants
                                  </TableCell>
                                  <TableCell style={{ width: "40%" }}>
                                    Cost per Ticket
                                  </TableCell>
                                  <TableCell style={{ width: "20%" }}>
                                    Total Cost
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {dataTickets.map((ticket, i) => {
                                  const participants =
                                    values[ticket.ticket_tag] || [];
                                  const ticketCost = ticket.ticket_price;

                                  if (flagPurchase === ticket.ticket_tag) {
                                    setTotalCostParticipants(
                                      ticketCost - totalCostDiscount,
                                    );
                                    const summingTotalCost =
                                      ticketCost - totalCostDiscount + 6500;

                                    setTotalCost(summingTotalCost);
                                  }

                                  return participants.length === 0 ? null : (
                                    <TableRow key={i}>
                                      <TableCell>
                                        {ticket.ticket_name}
                                      </TableCell>
                                      <TableCell>
                                        {participants.length}
                                      </TableCell>
                                      <TableCell>
                                        Rp.{" "}
                                        {ticket.ticket_price.toLocaleString(
                                          "id-ID",
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        Rp.{" "}
                                        {totalCostParticipants.toLocaleString(
                                          "id-ID",
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}

                                {!!totalCostDiscount && (
                                  <TableRow>
                                    <TableCell colSpan={2}></TableCell>
                                    <TableCell colSpan={1} align="left">
                                      Voucher:
                                    </TableCell>
                                    <TableCell>
                                      <strong>
                                        Rp. -
                                        {totalCostDiscount?.toLocaleString(
                                          "id-ID",
                                        )}
                                      </strong>
                                    </TableCell>
                                  </TableRow>
                                )}
                                {eventFormulaData?.length !== 0 && (
                                  <>
                                    {eventFormulaData?.map((item, index) => (
                                      <TableRow key={index}>
                                        <TableCell colSpan={2}></TableCell>
                                        <TableCell colSpan={1} align="left">
                                          <small>{item.name}</small>
                                        </TableCell>
                                        <TableCell>
                                          <strong>
                                            {item.operation === "*"
                                              ? `Rp. ${(totalCost * item.value).toLocaleString("id-ID")}`
                                              : `Rp. ${item.value.toLocaleString("id-ID")}`}
                                          </strong>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                    <TableRow>
                                      <TableCell colSpan={4} align="left">
                                        <Box
                                          sx={{
                                            width: "100%",
                                            marginY: 2,
                                            //paddingX: 4,
                                          }}
                                        >
                                          {/* Label Charity */}
                                          <p
                                            style={{
                                              marginBottom: "8px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Charity for Education:
                                          </p>
                                          {/* Slider */}
                                          <CustomSlider
                                            aria-label="Small steps"
                                            value={donationValue}
                                            onChange={(event, newValue) =>
                                              setDonationValue(newValue)
                                            }
                                            step={10000} // Kelipatan Rp 10.000
                                            min={0}
                                            max={2500000}
                                            valueLabelDisplay="auto"
                                            valueLabelFormat={(value) =>
                                              `Rp ${value.toLocaleString("id-ID")}`
                                            }
                                            marks={[
                                              { value: 0, label: "Rp 0" },
                                              {
                                                value: 2500000,
                                                label: "Rp 2.500.000",
                                              },
                                            ]}
                                            sx={{
                                              //display: "none"
                                              marginY: 0,
                                              marginX: 0,
                                              width: "100%",
                                              "& .MuiSlider-markLabel": {
                                                //display: "none",
                                                left: "85%",
                                                "&:last-of-type": {
                                                  left: "80%",
                                                  transform: "translateX(-50%)", // Geser posisi label maksimum
                                                  color: "blue", // Contoh tambahan styling
                                                  fontWeight: "bold",
                                                },
                                              },
                                            }}
                                          />
                                        </Box>
                                      </TableCell>
                                    </TableRow>

                                    <TableRow>
                                      <TableCell colSpan={2}></TableCell>
                                      <TableCell colSpan={1} align="left">
                                        <strong>Total Cost:</strong>
                                      </TableCell>
                                      <TableCell>
                                        <strong style={{ fontSize: "18px" }}>
                                          {realTotalCost?.toLocaleString(
                                            "id-ID",
                                          )}
                                        </strong>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>

                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, borderRadius: "20px" }}
                            disabled={isSubmitting || isloadingCheckVoucher}
                          >
                            Checkout
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              );
            }}
          </Formik>
        </Grid>
        <ConfirmationDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onConfirm={handleConfirm}
        />
      </Container>
    </Layout>
  );
};

export default BillingOrderForm;
