import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";

const columns = [
  { id: "jersey_claimed_id", label: "ID", minWidth: 100 },
  { id: "order_id", label: "Order ID", minWidth: 170 },
  { id: "claimed_status", label: "Status", minWidth: 120 },
  { id: "claimed_by", label: "Claim By", minWidth: 120 },
  { id: "claimed_by_name", label: "Claim By", minWidth: 120 },
  { id: "bib_number", label: "BIB Number", minWidth: 120 },
  { id: "created_date", label: "Created Date", minWidth: 150 },
  { id: "booking_full_name", label: "Full Name", minWidth: 170 },
  { id: "booking_phone_number", label: "Phone Number", minWidth: 170 },
  { id: "booking_email", label: "Email", minWidth: 200 },
];

export default function TableJerseyClaim({ accessToken }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [totalRows, setTotalRows] = React.useState(0);

  const handleRefetchListOfJerseyClaimed = React.useCallback(() => {
    setLoading(true);
    fetch(
      `https://api-prod.dtiketin.com/api/payments/jersey-report/5?page=${
        page + 1
      }&limit=${rowsPerPage}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
      .then((response) => response.json())
      .then((response) => {
        setRows(response.data.list || []);
        setTotalRows(response.data.total || 0);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [page, rowsPerPage, accessToken]);

  React.useEffect(() => {
    handleRefetchListOfJerseyClaimed();
  }, [handleRefetchListOfJerseyClaimed]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatCellValue = (value) => {
    return value === null || value === undefined || value === "" ? "-" : value;
  };

  return (
    <div className="w-full">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 5,
        }}
      >
        <Typography
          gutterBottom
          sx={{ color: "text.primary", fontWeight: "bold", fontSize: 18 }}
        >
          List of Jersey Claimed
        </Typography>
        <Button
          variant="contained"
          onClick={() => handleRefetchListOfJerseyClaimed()}
          sx={{
            width: "300px",
            marginTop: "10px",
            borderRadius: "20px",
            color: "white",
            backgroundColor: "red",
            "&:hover": {
              backgroundColor: "#d32f2f",
            },
          }}
        >
          Refresh
        </Button>
      </Box>
      <TableContainer className="max-h-[440px]">
        {loading ? (
          <div className="flex justify-center p-4">
            <CircularProgress className="w-8 h-8" />
          </div>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    className="min-w-[100px]"
                    style={{ minWidth: column.minWidth }}
                    sx={{ fontWeight: "bold" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.jersey_claimed_id}
                  className="hover:bg-gray-100"
                >
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {formatCellValue(row[column.id])}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        className="mt-4"
        rowsPerPageOptions={[1, 10, 25]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
